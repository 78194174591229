exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-arbeitsbasis-js": () => import("./../../../src/pages/main/arbeitsbasis.js" /* webpackChunkName: "component---src-pages-main-arbeitsbasis-js" */),
  "component---src-pages-main-coaching-js": () => import("./../../../src/pages/main/coaching.js" /* webpackChunkName: "component---src-pages-main-coaching-js" */),
  "component---src-pages-main-contact-js": () => import("./../../../src/pages/main/contact.js" /* webpackChunkName: "component---src-pages-main-contact-js" */),
  "component---src-pages-main-datenschutz-js": () => import("./../../../src/pages/main/datenschutz.js" /* webpackChunkName: "component---src-pages-main-datenschutz-js" */),
  "component---src-pages-main-hryoungprof-js": () => import("./../../../src/pages/main/hryoungprof.js" /* webpackChunkName: "component---src-pages-main-hryoungprof-js" */),
  "component---src-pages-main-impressum-js": () => import("./../../../src/pages/main/impressum.js" /* webpackChunkName: "component---src-pages-main-impressum-js" */),
  "component---src-pages-main-mbsr-js": () => import("./../../../src/pages/main/mbsr.js" /* webpackChunkName: "component---src-pages-main-mbsr-js" */),
  "component---src-pages-main-petra-js": () => import("./../../../src/pages/main/petra.js" /* webpackChunkName: "component---src-pages-main-petra-js" */),
  "component---src-pages-main-rainer-js": () => import("./../../../src/pages/main/rainer.js" /* webpackChunkName: "component---src-pages-main-rainer-js" */),
  "component---src-pages-main-stressregulation-achtsamkeit-js": () => import("./../../../src/pages/main/stressregulation_achtsamkeit.js" /* webpackChunkName: "component---src-pages-main-stressregulation-achtsamkeit-js" */)
}

